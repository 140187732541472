.iframe_container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;

  .iframe_block {
    height: 100%;
    width: calc(100% - 24px);
    background: rgba(0, 0, 0, 0.6);
    border: none;

    iframe {
      border: none;
    }

    @include phone {
      width: calc(100% - 16px);
    }
  }

  .close_block {
    height: 100%;
    width: 24px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);

    .close {
      width: 100%;
    }

    @include phone {
      width: 16px;
    }
  }
}