@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

@import "./utils/variables";
@import './utils//mixins';

@import "./common/footer";
@import "./common/header";
@import "./common/button";
@import './common/gameCard';
@import './common/circularProgress';
@import './common/iframeBlock';

@import "./pages/home";

* {
  margin: unset;
}

* {
  font-family: Rubik;
}

.blue_text {
  color: #0289EF;
}

.text_bold {
  font-weight: bold;
}

.required {
  font-size: inherit;
  color: red;
}