.header_container {
  display: flex;
  flex-direction: column;
  background-image: url(../../../public/images/headBackground.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  max-height: fit-content;
  min-height: 500px;
  padding: 15px 176px 196px 176px;
  gap: 106px;

  @include ipad {
    padding: 20px;
  }

  .top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .body_section {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 82px;
      line-height: 102px;
      color: #fff;
      text-align: start;

      @include phone {
        font-size: 56px;
      }
    }

    .content {
      max-width: 80%;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #fff;
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
}
