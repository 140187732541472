//---------------------------------------------
// 4. Viewport sizes
//---------------------------------------------

@mixin phone {
  @media (max-width: 767.8px) {
    @content;
  }
}
@mixin ipad {
  @media (max-width: 1023.8px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1023.9px) {
    @content;
  }
}