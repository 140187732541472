.btn_primary {
  cursor: pointer;
  background: linear-gradient(96.33deg, #0092ff 18%, #017bd7 66.1%);
  border-radius: 16px;
  color: #fff;
  border: none;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  &.middle {
    width: 160px;
    height: 62px;
  }

  &.big {
    width: 242px;
    height: 62px;
  }
}

.btn_secondary {
  cursor: pointer;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  padding: 24px 52px;
  background: #FFFFFF;
  color: #0289EF;
  border-radius: 20px;
  border: none;
}