.footer {
  height: 320;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  padding: 80px 0 20px 0;
  background: #0C1E2C;

  .content_text {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;

    a {
      color: #fff;
    }
  }

  .rights_reserved {
    font-weight: 400;
    font-size: 12px;
    line-height: 36px;
    color: #B6BCC0;
  }
}