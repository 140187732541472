.content_container {
  padding: 100px 176px 176px 200px;
  display: flex;
  flex-direction: column;
  gap: 152px;

  @include ipad {
    padding: 20px;
  }

  @include phone {
    gap: 60px;
    .title {
      font-size: 24px !important;
    }
  }

  .regular_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 42px;
    }

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 36px;
      max-width: 1070px;
    }
  }

  .games_block {
    display: flex;
    flex-direction: column;
    gap: 62px;
  }

  .title {
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
    color: #002038;

    &.centered {
      text-align: center;
    }

    &.start {
      text-align: start;
    }
  }

  .games_container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;

    div {
      width: 300px;
    }

    &.poker {
      justify-content: center;
      div {
        width: 350px;
      }
    }

    @include ipad {
      justify-content: center;
    }
  }

  @include ipad {
    .title {
      text-align: center !important;
    }
  }
}

.icons_block {
  display: flex;
  column-gap: 180px;
  flex-wrap: wrap;
  justify-content: center;

  .icon_item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &.center {
      align-items: center;
    }

    &.start {
      align-items: flex-start;
    }

    p {
      font-size: 22px;
    }
  }
}

.request_test_block {
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  border-radius: 36px;
  align-items: center;
  width: 100%;
  min-height: 282px;
  background-image: url(../../../public/images/request_send_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 42px;

  .title {
    color: #fff;
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
  }
}

.form_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  align-self: center;

  .row {
    display: flex;
    width: 100%;
    gap: 30px;

    &.center {
      align-items: center;
      justify-content: center;
    }

    &.button_row {
      margin-top: 20px;
    }

    .field_block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    }

    label {
      display: block;
      margin-bottom: 10px;
    }

    input {
      height: 40px;
    }

    textarea {
      height: 160px;
    }

    .field {
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-sizing: border-box;
      width: 100%;
    }

    textarea {
      resize: none;
    }
  }
}